import shopifySparkV1Defaults from '../shopify-spark-v1/config.js';

const wheelsFields = shopifySparkV1Defaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifySparkV1Defaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const wheelsCollectionUrl = '/collections/wheels';
const tiresCollectionUrl = '/collections/tires-all-2023';
const isOnHomePage = window.location.pathname === '/';

shopifySparkV1Defaults.Widgets.forEach((w) => {
  if (w.name === 'FacetPanel' || w.type === 'FacetPanel') {
    w.ignoreFields.push('FitmentType');
  }
});

const isAllProductsWheels = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Wheel');
};
const isAllProductsTires = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Tire');
};

export default {
  includes: ['shopify-spark-v1'],
  ...shopifySparkV1Defaults,
  Widgets: [
    ...shopifySparkV1Defaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'HomeVehicleWidget'].includes(w.name),
    ),
    {
      name: 'HeaderVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 770,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-wheel',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: wheelsCollectionUrl,
      selectFields: wheelsFields,
      shouldBeIsolated: () => !isAllProductsWheels(),
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-tire',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: tiresCollectionUrl,
      selectFields: tiresFields,
      shouldBeIsolated: () => !isAllProductsTires(),
    },
  ],
};
